exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adventure-site-js": () => import("./../../../src/pages/adventure-site.js" /* webpackChunkName: "component---src-pages-adventure-site-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-item-card-studio-js": () => import("./../../../src/pages/item-card-studio.js" /* webpackChunkName: "component---src-pages-item-card-studio-js" */),
  "component---src-pages-kickstarter-js": () => import("./../../../src/pages/kickstarter.js" /* webpackChunkName: "component---src-pages-kickstarter-js" */),
  "component---src-pages-mouse-js": () => import("./../../../src/pages/mouse.js" /* webpackChunkName: "component---src-pages-mouse-js" */),
  "component---src-pages-third-party-licence-js": () => import("./../../../src/pages/third-party-licence.js" /* webpackChunkName: "component---src-pages-third-party-licence-js" */),
  "component---src-pages-third-party-resources-js": () => import("./../../../src/pages/third-party-resources.js" /* webpackChunkName: "component---src-pages-third-party-resources-js" */),
  "component---src-templates-srd-template-js": () => import("./../../../src/templates/SRDTemplate.js" /* webpackChunkName: "component---src-templates-srd-template-js" */)
}

